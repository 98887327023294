import React, { useContext, useState } from 'react';
import { Container, Box, Typography, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import InboxIllustration from '../../resources/empty-inbox.svg';
import PendingTransactionList from '../../utils/PendingTransactionList';
import PendingReviewList from '../../components/reviews/PendingReviewList';
import ContractList from '../../components/contracts/ContractList'; // Reusable component
import { DataContext } from '../../context/DataContext';
import { getPendingTransactions, getPendingReviews } from '../../api';

export default function InboxUser() {
  const { user } = useContext(UserContext);
  const { data, refreshData } = useContext(DataContext); // Access preloaded data
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const pendingTransactions = data.pendingTransactions || [];
  const pendingReviews = data.pendingReviews || [];
  const contracts = (data.contracts || []).filter(contract =>
    ['SUBMITTED', 'ACCEPTED'].includes(contract.status)
  );
  // Prepare tab data directly based on pending transactions, reviews, and contracts
  const tabs = [
    {
      label: t('transactions'),
      content: 'transactions',
      show: pendingTransactions.length > 0,
    },
    {
      label: t('reviews'),
      content: 'reviews',
      show: pendingReviews.length > 0,
    },
    {
      label: t('pending_contracts'),
      content: 'contracts',
      show: contracts.length > 0,
    },
  ].filter(tab => tab.show); // Only show tabs with data

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const refreshPendingTransactions = async () => {
    await refreshData('pendingTransactions', getPendingTransactions);
  };

  const refreshPendingReviews = async () => {
    await refreshData('pendingReviews', getPendingReviews);
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('inbox')}</Typography>
      </Box>

      {tabs.length > 0 ? (
        <>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>

          <Box my={3}>
            {tabs[activeTab]?.content === 'transactions' && (
              <PendingTransactionList
                transactions={pendingTransactions}
                user={user}
                refresh={refreshPendingTransactions}
              />
            )}
            {tabs[activeTab]?.content === 'reviews' && (
              <PendingReviewList
                reviews={pendingReviews}
                refresh={refreshPendingReviews}
                role={user.role}
              />
            )}
            {tabs[activeTab]?.content === 'contracts' && (
              <ContractList contracts={contracts} expandable = {false}/>
            )}
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <Box sx={{ width: '60%', maxWidth: '150px', mb: 2 }}>
            <img src={InboxIllustration} alt="No Actions Required" style={{ animation: 'bounce 2s infinite' }} />
          </Box>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {t('no_actions_required')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('you_are_all_caught_up')}
          </Typography>
        </Box>
      )}
    </Container>
  );
}
