import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  titleKey = "confirm_title", // Translation key for title
  messageKey = "confirm_message", // Translation key for message
  cancelLabelKey = "cancel_button", // Translation key for cancel button
  confirmLabelKey = "confirm_button", // Translation key for confirm button
  confirmButtonColor = "primary", // Default confirm button color
  cancelButtonColor = "secondary", // Default cancel button color
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 3, // Rounded corners for better visual appeal
          padding: 2,
          minWidth: 400, // Ensure the dialog has a consistent size
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold' }}>{t(titleKey)}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'text.secondary', fontSize: '1rem' }}>
          {t(messageKey)}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          onClick={onClose}
          color={cancelButtonColor}
          variant="outlined"
          sx={{
            textTransform: 'none', // Keep text capitalization normal
          }}
        >
          {t(cancelLabelKey)}
        </Button>
        <Button
          onClick={onConfirm}
          color={confirmButtonColor}
          variant="contained"
          sx={{
            textTransform: 'none',
          }}
        >
          {t(confirmLabelKey)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
