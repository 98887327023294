import React, { useContext } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import ContractList from './ContractList';

export default function ContractAdmin() {
    const { data } = useContext(DataContext); // Access preloaded data
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    // Filter active contracts
    const contracts = (data.contracts || []).filter(contract =>
        ['ACTIVE'].includes(contract.status)
    );

    // Group contracts by concierge
    const groupedContracts = contracts.reduce((acc, contract) => {
        const conciergeId = contract.concierge_id || 'Unknown';
        if (!acc[conciergeId]) {
            acc[conciergeId] = {
                conciergeName: contract.concierge_name || t('unknown_concierge'),
                contracts: [],
            };
        }
        acc[conciergeId].contracts.push(contract);
        return acc;
    }, {});

    const groupedContractsArray = Object.values(groupedContracts);

    return (
        <Container maxWidth="md">
            <Box display="flex" justifyContent="center" my={3}>
                <Typography variant="h4">{t('contracts')}</Typography>
            </Box>

            {groupedContractsArray.length > 0 ? (
                groupedContractsArray.map((group, index) => (
                    <Box key={index} my={3}>
                        <ContractList
                            contracts={group.contracts}
                            title={group.conciergeName}
                            userRole={user.role}
                            expandable={group.contracts.length > 0}
                        />
                    </Box>
                ))
            ) : (
                <Box display="flex" justifyContent="center" my={3}>
                    <Typography variant="body1">{t('no_active_contracts')}</Typography>
                </Box>
            )}
        </Container>
    );
}
