import React, { useContext } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import ContractList from './ContractList';

export default function Contracts() {
    const { data } = useContext(DataContext); // Access preloaded data
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const accepted_contracts = (data.contracts || []).filter(contract =>
        ['ACTIVE'].includes(contract.status)
    );

    const cancelled_contracts = (data.contracts || []).filter(contract =>
        ['REJECTED', 'REFUSED'].includes(contract.status)
    );

    const expandable = accepted_contracts.length > 0 && cancelled_contracts.length > 0;
    return (
        <Container maxWidth="md">
          <Box display="flex" justifyContent="center" my={3}>
            <Typography variant="h4">{t('contracts')}</Typography>
          </Box>

          {accepted_contracts && <ContractList
            contracts={accepted_contracts}
            title={t('validated_contracts')}
            userRole={user.role}
            expandable = {expandable}
          />}

          {cancelled_contracts.length > 0 &&<ContractList
            contracts={cancelled_contracts}
            title={t('non_validated_contracts')}
            userRole={user.role}
            expandable = {expandable}
          />}

        </Container>
      );
}
