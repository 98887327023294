import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { getCashTransactions, cash_deposit, cash_withdrawal, deleteTransaction, getUserStatsHistory, cash_update } from '../../api';
import { useNotification } from '../../context/NotificationContext';
import {
  Box,
  Container,
  Fab,
  Typography,
  Button
} from '@mui/material';
import FormTransaction from './FormTransaction';
import { CASH_TRANSACTION } from '../../constants';
import MainCard from '../../utils/MainCard';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import TransactionList from '../../utils/TransactionList'; // Adjust the path as needed
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import ConfirmDialog from '../../utils/ConfirmDialog';

function Savings() {
  const { user, refreshUser } = useContext(UserContext);
  const { data, refreshData, refreshAll } = useContext(DataContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const [isAdding, setIsAdding] = useState(false); // Visibility of the transaction form
  const [isEditing, setIsEditing] = useState(false); // Editing mode
  const [transactionToEdit, setTransactionToEdit] = useState(null); // Transaction being edited
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);

  const transactions = data.cashTransactions || [];
  const statsHistory = data.userStatsHistory || {};

  // Open confirmation dialog
  const confirmDelete = (transactionId) => {
    setTransactionToDelete(transactionId);
    setDeleteDialogOpen(true);
  };

  // Open edit form
  const handleEdit = (transaction) => {
    setTransactionToEdit(transaction); // Pass transaction object for editing
    setIsEditing(true);
  };

  const handleDelete = async () => {
    try {
      if (transactionToDelete) {
        await deleteTransaction(transactionToDelete);
        await refreshAll(user.role);
        refreshUser();
        showNotification(t('transaction_deleted_successfully'));
      }
    } catch (error) {
      console.error('An error occurred while deleting transaction:', error);
      showNotification('Cash operation failed : ' + error.response?.data?.detail || '', 'error');
    } finally {
      setDeleteDialogOpen(false);
      setTransactionToDelete(null);
    }
  };

  const handleNewTransaction = async (formData) => {
    try {
      formData.user_id = user.id;
      if (formData.type === CASH_TRANSACTION.DEPOSIT) await cash_deposit(formData);
      else if (formData.type === CASH_TRANSACTION.WITHDRAW) await cash_withdrawal(formData);
      else throw new Error('Invalid transaction type');

      await refreshAll(user.role);
      refreshUser();

      showNotification(t('cash_operation_successful'));
      setIsAdding(false);
    } catch (error) {
      console.error('An error occurred while adding transaction:', error);
      showNotification('Cash operation failed : ' + error.response?.data?.detail || '', 'error');
    }
  };

  const handleUpdateTransaction = async (formData) => {
    try {

      await cash_update(transactionToEdit.id, { id: transactionToEdit.id, title:formData.description, user_id:transactionToEdit.user_id, ...formData });

      await refreshAll(user.role);
      refreshUser();

      showNotification(t('cash_operation_updated_successfully'));
      setIsEditing(false);
      setTransactionToEdit(null);
    } catch (error) {
      console.error('An error occurred while updating transaction:', error);
      showNotification('Cash operation update failed: ' + error.response?.data?.detail || '', 'error');
    }
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="center" my={0} sx={{ mb: 3 }}>
        <MainCard
          title={t('personal_contribution')}
          value={user.contributions}
          m_change={statsHistory.contributions_mtd}
          y_change={statsHistory.contributions_ytd}
          currency={user.currency}
          tooltip={t('tooltip_savings')}
          size="h4"
        />
      </Box>

      {transactions.length === 0 ? (
        <Box textAlign="center" mt={5}>
          <Typography variant="subtitle1" color="text.secondary">
            {t('no_savings_quote')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsAdding(true)}
            sx={{ mt: 3 }}
          >
            {t('add_your_first_cash_transaction')}
          </Button>
        </Box>
      ) : (
        <>
          <TransactionList
            title={t('deposit_withdrawal')}
            transactions={transactions}
            currency={user.currency}
            onEdit={handleEdit} // Pass the transaction to edit
            onDelete={confirmDelete} // Open confirmation dialog
          />
          <Fab
            color="primary"
            aria-label={t('add_transaction')}
            onClick={() => setIsAdding(true)}
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
            <AddIcon />
          </Fab>
        </>
      )}

      {/* Form for Adding or Editing */}
      {(isAdding || isEditing) && (
        <FormTransaction
          user={user}
          apply={isEditing ? handleUpdateTransaction : handleNewTransaction}
          open={isAdding || isEditing}
          onClose={() => {
            setIsAdding(false);
            setIsEditing(false);
            setTransactionToEdit(null);
          }}
          initialData={transactionToEdit} // Pass initial data for editing
        />
      )}

      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        title={t('delete_transaction_title')}
        message={t('delete_transaction_message')}
        cancelLabel={t('cancel_button')}
        confirmLabel={t('delete_button')}
        confirmButtonColor="error"
      />
    </Container>
  );
}

export default Savings;
