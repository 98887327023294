import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import ContractUserPro from './ContractUserPro';
import ContractAdmin from './ContractAdmin';
import { Container } from '@mui/material';

export default function Contract() {
  const { user } = useContext(UserContext);

  const renderContract = () => {
    switch (user.role) {
      case 'INDIVIDUAL':
        return <ContractUserPro />;
      case 'PRO':
        return <ContractUserPro />;
      case 'ADMIN':
        return <ContractAdmin />;;
      default:
        return null;
    }
  };

  return (
    <Container>
      {renderContract()}
    </Container>
  );
}
