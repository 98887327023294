import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import {
  configCurrencies,
  configAssetTypes,
  configAssetCategories,
  configAssetExploitations,
  configTransactionTypes,
  configConciergeCategories,
  configExpenseTypes,
  configIncomeTypes,
  configDebtTypes,
} from '../api';

export const DataContext = createContext();

export const DataProvider = ({ children, loadingConfig }) => {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('data')) || null);
  const [configs, setConfigs] = useState(JSON.parse(localStorage.getItem('configs')) || {}); // Static configurations
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Fetches data for a specific key and updates state and localStorage.
   */
  const refreshData = useCallback(async (key, apiFunc) => {
    setLoading((prev) => ({ ...prev, [key]: true }));
    try {
      const response = await apiFunc();
      setData((prev) => {
        const updatedData = { ...prev, [key]: response.data };
        console.log('Updated data:', updatedData); // Debug log
        localStorage.setItem('data', JSON.stringify(updatedData));
        return updatedData;
      });
    } catch (error) {
      console.error(`Failed to refresh ${key}:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [key]: false }));
    }
  }, []);

  /**
   * Fetches data for all keys in the current user's role configuration.
   */
  const refreshAll = useCallback(
    async (role) => {
      const userConfig = loadingConfig[role] || {};
      const entries = Object.entries(userConfig);

      try {
        console.log('Starting refreshAll for role:', role);

        // Use Promise.allSettled to ensure all requests are processed
        const results = await Promise.allSettled(
          entries.map(async ([key, apiFunc]) => {
            const response = await apiFunc();
            return [key, response.data];
          })
        );

        // Process successful responses
        const successfulResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((result) => result.value);

        setData((prev) => {
          const updatedData = successfulResults.reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, { ...prev });

          console.log('Updated data in state:', updatedData);
          localStorage.setItem('data', JSON.stringify(updatedData));
          return updatedData;
        });

        // Log errors for failed requests
        results
          .filter((result) => result.status === 'rejected')
          .forEach((result) => {
            console.error('Failed to refresh data:', result.reason);
          });
      } catch (error) {
        console.error('Unexpected error in refreshAll:', error);
        setError(error);
      }
    },
    [loadingConfig]
  );


  /**
   * Fetches and stores static configuration data.
   */
  const loadConfigs = useCallback(async () => {
    const configFetchers = {
      currencies: configCurrencies,
      assetTypes: configAssetTypes,
      assetCategories: configAssetCategories,
      assetExploitations: configAssetExploitations,
      transactionTypes: configTransactionTypes,
      conciergeCategories: configConciergeCategories,
      expenseTypes: configExpenseTypes,
      incomeTypes: configIncomeTypes,
      debtTypes: configDebtTypes,
    };

    try {
      const configEntries = await Promise.all(
        Object.entries(configFetchers).map(async ([key, fetcher]) => {
          const response = await fetcher();
          return [key, response.data];
        })
      );

      const newConfigs = Object.fromEntries(configEntries);
      setConfigs(newConfigs);
      localStorage.setItem('configs', JSON.stringify(newConfigs)); // Cache configurations locally
    } catch (err) {
      console.error('Error loading configurations:', err);
      setError(err);
    }
  }, []);

  // Clear data (e.g., on logout)
  const resetData = useCallback(() => {
    setData({});
    setConfigs({});
    localStorage.removeItem('data');
    localStorage.removeItem('configs');
  }, []);

  /**
   * Retrieves the role-specific config.
   */
  const getConfig = useCallback(
    (role) => loadingConfig[role] || {},
    [loadingConfig]
  );

  /**
   * Load static configurations on mount.
   */
  useEffect(() => {
    loadConfigs();
  }, [loadConfigs]);

  return (
    <DataContext.Provider
      value={{
        data,
        configs, // Expose static configurations
        refreshData,
        refreshAll,
        loadConfigs,
        getConfig,
        resetData,
        loading,
        error,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
