import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';
import { DataContext } from '../../context/DataContext';

Highcharts.setOptions({
  colors: Highcharts.getOptions().colors.map(color => ({
    radialGradient: {
      cx: 0.5,
      cy: 0.3,
      r: 0.7
    },
    stops: [
      [0, color],
      [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
    ]
  }))
});

const UserAllocPie = ({ currency, cash_balance, assets}) => {
  const { t } = useTranslation();

  console.log("UserAllocPie: assets in component ", assets);

  // filter to keep only asset with active=true
  const assetList = assets ? assets.filter(asset => asset.active) : null;

  if (!assets) {
    return <CircularProgress />;
  }

  const generateChartData = () => {
    const assetAllocation = {};

    // Add liquidity to the allocation
    assetAllocation[t('liquidity')] = {
      value: cash_balance,
      currency: currency,
    };

    // Process assets if any
    assetList?.forEach((asset) => {
      const type = t(asset.type);
      const value = asset.value;

      if (!assetAllocation[type]) {
        assetAllocation[type] = {
          value: 0,
          currency: currency,
        };
      }

      assetAllocation[type].value += value;
    });

    // Calculate total value
    const totalValue = Object.values(assetAllocation).reduce((total, type) => total + type.value, 0);

    console.log("assetAllocation ", assetAllocation);
    // Generate chart data with percentages
    return Object.keys(assetAllocation).map(type => ({
      name: type,
      y: (assetAllocation[type].value / totalValue) * 100, // Calculate the percentage
      currency: assetAllocation[type].currency,
    }));
  };

  const chartData = generateChartData();

  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      reflow: true,
      plotShadow: false,
    },
    title: null,
    tooltip: {
      pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
      }
    },
    legend: {
      enabled: true
    },
    series: [
      {
        name: t('allocation'),
        data: chartData,
      },
    ],
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default UserAllocPie;
