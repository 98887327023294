import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import { UserProvider } from './context/UserContext';
import { NotificationProvider } from './context/NotificationContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-supabase'
import { DataProvider } from './context/DataContext';
import AppRoutes from './AppRoutes';
import supabase from './supabase'

import {
  getAssets,
  getConcierges,
  getConcierge,
  getContracts,
  getDebts,
  getUserStatsHistory,
  getManagedAssets,
  getPendingTransactions,
  getPendingReviews,
  getUserCashflowChart,
  getUserWealthChart,
  getEstimations,
  getStatsHistoryConcierge,
  getUserConcierge,
  getConciergeRevenueChart,
  getCashTransactions,
  getRequestedReviews,
} from './api';

import './App.css';
import './form.css';
//import './shards.min.css';

// Create a client
const queryClient = new QueryClient();


// Role-based loading configuration
const loadingConfig = {
  ADMIN: {
    assets: getAssets,
    concierges: getConcierges,
    contracts: getContracts,
    pendingReviews: getPendingReviews,
  },
  PRO: {
    managedAssets: getManagedAssets,
    conciergeStatsHistory: getStatsHistoryConcierge,
    user_concierge: getUserConcierge,
    concierge: getConcierge,
    conciergeRevenueChart: getConciergeRevenueChart,
  },
  INDIVIDUAL: {
    assets: getAssets,
    userStatsHistory: getUserStatsHistory,
    userCashflowChart: getUserCashflowChart,
    userWealthChart: getUserWealthChart,
    estimations: getEstimations,
    concierges: getConcierges,
    contracts: getContracts,
    pendingTransactions: getPendingTransactions,
    requestedReviews: getRequestedReviews,
    debts: getDebts,
    cashTransactions: getCashTransactions
  },
};

function App() {
    return (
      <QueryClientProvider client={queryClient} >
        <UserProvider>
          <DataProvider loadingConfig={loadingConfig}>
            <LanguageProvider>
            <NotificationProvider>
            <Provider value={supabase}>
                <Router>
                    <AppRoutes />
                </Router>
                </Provider>
              </NotificationProvider>
            </LanguageProvider>
          </DataProvider>
        </UserProvider>
      </QueryClientProvider>
    );
}
export default App;
