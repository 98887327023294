import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatAmount } from '../../utils';
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../context/UserContext';

const UserCashflowChart = ({ currency, userCashflowChart }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  if (!userCashflowChart) {
    return <CircularProgress />;
  }

  const prepareChartData = () => {
    // Check if userCashflowChart exists and has a 'data' property
    if (!userCashflowChart || Object.keys(userCashflowChart).length === 0) {
      return { categories: [], incomeData: [], expenseData: [], cashflowData: [] };
    }

    const cashflowdata = userCashflowChart;

    // Ensure cashflowdata is an object before proceeding
    if (typeof cashflowdata !== 'object') {
      return { categories: [], incomeData: [], expenseData: [], cashflowData: [] };
    }

    const categories = Object.keys(cashflowdata);
    const incomeData = categories.map(date => cashflowdata[date]?.income || 0);
    const expenseData = categories.map(date => cashflowdata[date]?.expense || 0);
    const cashflowData = incomeData.map((income, index) => income - expenseData[index]);

    return { categories, incomeData, expenseData, cashflowData };
  };

  const { categories, incomeData, expenseData, cashflowData } = prepareChartData();

  const options = {
    chart: {
      type: 'column',
      zoomType: 'x',
      backgroundColor: 'transparent',
      height: 450,
      panning: true,
      panKey: 'shift',
    },
    title: null,
    xAxis: {
      categories, // Set x-axis categories directly
      tickmarkPlacement: 'on',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Arial, sans-serif',
        },
        formatter: function () {
          return this.value; // Display categories correctly
        },
      },
    },
    yAxis: {
      title: null,
      labels: {
        formatter: function () {
          return formatAmount(this.value, currency, true);
        },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: t('income'),
        data: incomeData,
        color: Highcharts.getOptions().colors[0], // Adjust color as needed
      },
user.role !== 'PRO' && {
      name: t('expense'),
      data: expenseData,
      color: Highcharts.getOptions().colors[7],
    },
    user.role !== 'PRO' && {
      name: t('cashflow'),
      type: 'spline',
      data: cashflowData,
      lineColor: Highcharts.getOptions().colors[2],
      color: Highcharts.getOptions().colors[4],
      fillOpacity: 0.1,
      tooltip: {
        valueSuffix: ` ${currency}`,
      },
    },
  ].filter(Boolean), // Remove falsy values (null/undefined)
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        let tooltipHtml = `<b>${this.x}</b><br/>`;
        this.points.forEach(point => {
          tooltipHtml += `${point.series.name}: ${formatAmount(point.y, currency, true)}<br/>`;
        });
        return tooltipHtml;
      },
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          chart: {
            scrollablePlotArea: {
              minWidth: 300, // Adjust for desired mobile display width
              scrollPositionX: 1,
            },
          },
        },
      }],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default UserCashflowChart;
