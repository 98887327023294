import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, CircularProgress, LinearProgress } from '@mui/material';
import { DataContext } from '../../context/DataContext';


const Loading = ({ userConfig }) => {
  const navigate = useNavigate();
  const { refreshData } = useContext(DataContext);

  const [progress, setProgress] = useState(0);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);


  useEffect(() => {
    const loadMainAssets = async () => {
      const entries = Object.entries(userConfig);
      // Split into critical and non-critical tasks
      const criticalTasks = entries.slice(0, Math.ceil(entries.length / 2));
      const nonCriticalTasks = entries.slice(Math.ceil(entries.length / 2));

      let completedTasks = 0;
      const totalTasks = entries.length;

      try {
        // Load critical data eagerly
        for (const [key, apiFunc] of criticalTasks) {
          await refreshData(key, apiFunc);
          completedTasks++;
          setProgress((completedTasks / totalTasks) * 100);
        }

        // Redirect after first half is loaded
        setIsLoadingComplete(true);

       // Load non-critical data lazily in the background
        for (const [key, apiFunc] of nonCriticalTasks) {
          await refreshData(key, apiFunc);
          completedTasks++;
          setProgress((completedTasks / totalTasks) * 100);
        }

      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadMainAssets();
  }, [userConfig, refreshData]);

  useEffect(() => {
    if (isLoadingComplete) {
      navigate('/dashboard');
    }
  }, [isLoadingComplete, navigate]);

  return (
    <Container mb={2}>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        p: 3,
      }}
    >
      <CircularProgress variant="determinate" value={progress} size={100} />
      <Typography sx={{ mt: 2 }}>{`Loading: ${Math.round(progress)}%`}</Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ width: '80%', mt: 2, height: 8, borderRadius: 2 }}
      />
    </Box>
    </Container>
  );
};

export default Loading;
