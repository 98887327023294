import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Collapse,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';

const ContractList = ({
  contracts,
  title,
  onApprove,
  onReject,
  expandable = true,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    if (expandable) {
      setExpanded((prev) => !prev);
    }
  };

  const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

  return (
    <Box my={3}>
      <Typography variant="h6" onClick={handleExpandClick} sx={{ cursor: expandable ? 'pointer' : 'default' }}>
        {title}
        {expandable && (
          <IconButton size="small">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Typography>

      <Collapse in={expanded || !expandable} timeout="auto" unmountOnExit>
        {contracts.length > 0 ? (
          contracts.map((contract) => (
            <Card key={contract.id} sx={{ my: 2 }}>
              <CardContent>
                <Typography variant="subtitle1"><strong>{t('owner_name')}:</strong> {contract.user_name}</Typography>
                <Typography variant="subtitle1"><strong>{t('asset_name')}:</strong> {contract.asset_name}</Typography>
                <Typography variant="subtitle1"><strong>{t('asset_category')}:</strong> {t(contract.asset_category)}</Typography>
                <Typography variant="subtitle1"><strong>{t('asset_type')}:</strong> {t(contract.asset_type)}</Typography>
                <Typography variant="subtitle1"><strong>{t('concierge')}:</strong> {contract.concierge_name}</Typography>
                <Typography variant="subtitle1">
                  <strong>{t('contract_duration')}:</strong> {`${formatDate(contract.created_at)} - ${formatDate(contract.expiry_date)}`}
                </Typography>
                <Typography variant="subtitle1"><strong>{t('message')}:</strong> {contract.message}</Typography>
                <Typography variant="subtitle1"><strong>{t('status')}:</strong> {t(contract.status)}</Typography>
              </CardContent>
                <CardActions>
                {onReject && contract.status === 'PENDING' && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => onReject(contract.id)}
                      sx={{ mr: 1 }}
                    >
                      {t('delete')}
                    </Button>
                  )}
                  {onApprove && contract.status === 'PENDING' && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onApprove(contract.id)}
                    >
                      {t('approve')}
                    </Button>
                  )}
                </CardActions>
            </Card>
          ))
        ) : (
          <Typography variant="body2">{t('no_contract')}</Typography>
        )}
      </Collapse>
    </Box>
  );
};

export default ContractList;
