import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment'; // Task Management
import PeopleIcon from '@mui/icons-material/People'; // Client Management
import DescriptionIcon from '@mui/icons-material/Description'; // Contracts
import InboxIcon from '@mui/icons-material/Inbox'; // Inbox / Communication
import BarChartIcon from '@mui/icons-material/BarChart'; // Reports/Performance
import OtherHousesIcon from '@mui/icons-material/OtherHouses'; // Assets
import SettingsIcon from '@mui/icons-material/Settings'; // Settings
import PersonIcon from '@mui/icons-material/Person'; // Profile
import './Sidebar.css'; // Assuming shared styles

const SidebarPro = ({ isMobile, isVisible, toggleSidebar }) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isVisible && !event.target.closest(".sidebar")) {
        if (isMobile) toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside, { passive: true });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, toggleSidebar]);

  const noShow = ['/login', '/signup'].includes(location.pathname);
  if (noShow) return null;

  const handleMenuItemClick = () => {
    if (isMobile) toggleSidebar();
  };

  return (
    <aside className={`sidebar ${isVisible ? 'visible' : ''}`}>
      <Navbar className="flex-column">
        <Nav className="flex-column nav-section">
          <hr className="nav-divider" />
          {/* Dashboard */}
          <Link className="nav-link" to="/dashboard" onClick={handleMenuItemClick}>
            <DashboardIcon /> <span>{t('dashboard')}</span>
          </Link>

          {/* Assets Management */}
          <Link className="nav-link" to="/assets" onClick={handleMenuItemClick}>
            <OtherHousesIcon /> <span>{t('assets')}</span>
          </Link>

          {/* Contracts */}
          <Link className="nav-link" to="/documents" onClick={handleMenuItemClick}>
            <DescriptionIcon /> <span>{t('contracts')}</span>
          </Link>

          {/* Client Management
          <Link className="nav-link" to="/clients" onClick={handleMenuItemClick}>
            <PeopleIcon /> <span>{t('clients')}</span>
          </Link>*/}

          <hr className="nav-divider" />

          {/* Inbox */}
          <Link className="nav-link" to="/inbox" onClick={handleMenuItemClick}>
            <InboxIcon /> <span>{t('inbox')}</span>
          </Link>


          {/* Transactions Management*/}
          <Link className="nav-link" to="/management" onClick={handleMenuItemClick}>
            <AssignmentIcon /> <span>{t('transactions')}</span>
          </Link>

          {/* Performance Reports
          <Link className="nav-link" to="/reports" onClick={handleMenuItemClick}>
            <BarChartIcon /> <span>{t('performance')}</span>
          </Link>
          */}
          <hr className="nav-divider" />

          {/* Settings */}
          <Link className="nav-link" to="profile" onClick={handleMenuItemClick}>
            <PersonIcon /> <span>{t('profile')}</span>
          </Link>
        </Nav>
      </Navbar>
      <div className="sidebar-footer">
        Yefina © 2024
      </div>
    </aside>
  );
};

export default SidebarPro;
