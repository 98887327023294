import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import {
  Box, Container, Fab, Grid, Button, Fade, Tab, Tabs, Typography, Modal, ToggleButton, ToggleButtonGroup
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import MainCard from '../../utils/MainCard';
import { deleteAsset, createAsset } from '../../api';
import AssetCreationWizard from './create/AssetCreationWizard';
import { useTranslation } from 'react-i18next';
import AssetCard from '../../utils/AssetCard';
import SwipeableViews from 'react-swipeable-views';
import { formatAmount } from '../../utils';
import ConfirmDialog from '../../utils/ConfirmDialog';
import LoadingModal from '../../utils/LoadingModal';
import { useNotification } from '../../context/NotificationContext';
import {getAssets, getUserStatsHistory} from '../../api';

const AssetsUser = ({ user }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [assetView, setAssetView] = useState('active'); // State to manage asset view (active/sold)
  const [typedAssets, setTypedAssets] = useState({});
  const [totalValue, setTotalValue] = useState(0);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [assetIdToDelete, setAssetIdToDelete] = useState(null);
  const { data, refreshData } = useContext(DataContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasSoldAssets, setHasSoldAssets] = useState(false); // State to determine if there are sold assets
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const [cachedActiveAssets, setCachedActiveAssets] = useState(null);
  const [cachedSoldAssets, setCachedSoldAssets] = useState(null);

  // Handle data based on assetView
  useEffect(() => {
    if (!data.assets) {
      // Assets not yet loaded, avoid processing
      return;
    }

    try {
      // Separate assets into active and sold
      const activeAssets = data.assets.filter((asset) => asset.active);
      const soldAssets = data.assets.filter((asset) => !asset.active);


      // Set the sold assets state based on availability
      setHasSoldAssets(soldAssets.length > 0);

      // Cache and process assets based on the asset view
      if (assetView === 'active') {
        setCachedActiveAssets(activeAssets);
        setTypedAssets(groupAssetsByType(activeAssets));
        setTotalValue(calculateTotalValue(activeAssets));
      } else {
        setCachedSoldAssets(soldAssets);
        setTypedAssets(groupAssetsByType(soldAssets));
        setTotalValue(calculateTotalValue(soldAssets));
      }
    } catch (error) {
      console.error('Error processing assets:', error);
      showNotification(t('failed_retrieving_assets_data'), 'error');
    }
  }, [data.assets, assetView, showNotification]);

  const userStatsHistory = data.userStatsHistory;

  // Function to handle the view toggle (Active/Sold)
  const handleViewChange = async (event, newView) => {
    if (newView !== null) {
      setAssetView(newView);

      // Check if cached assets exist and update the state with cached data
      if (newView === 'active' && cachedActiveAssets) {
        setTypedAssets(groupAssetsByType(cachedActiveAssets));
        setTotalValue(calculateTotalValue(cachedActiveAssets));
      } else if (newView === 'sold' && cachedSoldAssets) {
        setTypedAssets(groupAssetsByType(cachedSoldAssets));
        setTotalValue(calculateTotalValue(cachedSoldAssets));
      } else {
        // Fetch from the backend if cache is not available
        refreshData('assets', getAssets);
      }
    }
  };

  // Helper function to group assets by type
  const groupAssetsByType = (assets) => {
    return assets.reduce((acc, asset) => {
      const { type } = asset;
      acc[type] = acc[type] || { items: [], totalValue: 0 };
      acc[type].items.push(asset);
      acc[type].totalValue += asset.value;
      return acc;
    }, {});
  };

  // Helper function to calculate total asset value
  const calculateTotalValue = (assets) => {
    return assets.reduce((sum, asset) => sum + (asset.value || 0), 0);
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleDeleteAsset = (assetId) => {
    setAssetIdToDelete(assetId);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!assetIdToDelete) return;
    setIsCreating(true);
    try {
      await deleteAsset(assetIdToDelete);
      refreshData('assets', getAssets);
      showNotification(t('asset_deleted_successfully'), 'success');
      refreshData('userStatsHistory', getUserStatsHistory);
    } catch (error) {
      showNotification(t('failed_retrieving_assets_data') + ': ' + error.response?.data?.detail, 'error');
    } finally {
      setIsCreating(false);
      setIsDialogOpen(false);
    }
  };

  const handleOpenWizard = () => setIsWizardOpen(true);
  const handleCloseWizard = () => setIsWizardOpen(false);

  const handleWizardSubmitAsset = async (formData, flagNewDeposit, details, forecast, location = null) => {
    try {
      setIsCreating(true);
      const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
      details = isEmptyObject(details) ? null : details;
      location = isEmptyObject(location) ? null : location;
      forecast = isEmptyObject(forecast) ? { cashflow: 0, appreciation: 10, period: 10 } : forecast;
      await createAsset(formData, details, forecast, location, flagNewDeposit);
      showNotification(t('asset_created_successfully'), 'success');
      refreshData('assets', getAssets);
      refreshData('userStatsHistory', getUserStatsHistory);
    } catch (error) {
      showNotification(t('failed_retrieving_assets_data') + ': ' + error.response?.data?.detail, 'error');
    } finally {
      setIsCreating(false);
      setIsWizardOpen(false);
    }
  };

  return (
    <Container maxWidth="md">
          {/* Display the ToggleButtonGroup only if there are sold assets */}
          {hasSoldAssets && (
            <Box display="flex" justifyContent="center" my={2}>
              <ToggleButtonGroup
                value={assetView}
                exclusive
                onChange={handleViewChange}
                aria-label="Asset View Toggle"
              >
                <ToggleButton value="active" aria-label="Active Assets">
                  {t('running_assets')}
                </ToggleButton>
                <ToggleButton value="sold" aria-label="Sold Assets">
                  {t('sold_assets')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}

          {Object.keys(typedAssets).length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h6" gutterBottom>
              {t('welcome_to_assets')}
              </Typography>
              {!hasSoldAssets && <Typography variant="subtitle1" color="text.secondary">
                {t('no_assets_prompt')}
                </Typography>}
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenWizard}
                sx={{ mt: 3 }}
              >
                {t('add_your_first_asset')}
              </Button>
            </Box>
          ) : (
            <>
              <Box display="flex" justifyContent="center" my={2}>
                {assetView==='active' && <MainCard
                  title={t('assets_total_value')}
                  value={totalValue}
                  currency={user.currency}
                  m_change={userStatsHistory ? userStatsHistory.value_mtd : 0}
                  y_change={userStatsHistory ? userStatsHistory.value_ytd : 0}
                  size='h4'
                />}
                {assetView!=='active' && <MainCard
                  title={t('assets_total_value')}
                  value={totalValue}
                  currency={user.currency}
                  m_change={null}
                  y_change={null}
                  size='h4'
                />}
              </Box>

              <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" style={{ marginBottom: '10px' }}>
                {Object.keys(typedAssets).map((type, index) => (
                  <Tab
                    label={
                      <>
                        {t(type)}
                        <Typography variant="subtitle1" component="span" style={{ marginLeft: '8px' }}>
                          {formatAmount(typedAssets[type].totalValue, user.currency)}
                        </Typography>
                      </>
                    }
                    key={type}
                  />
                ))}
              </Tabs>

              <SwipeableViews axis="x" index={activeTab} onChangeIndex={setActiveTab}>
                {Object.entries(typedAssets).map(([type, assets], index) => (
                  <TabPanel value={activeTab} index={index} key={type}>
                    <Grid container spacing={2}>
                      {assets.items.map((asset) => (
                        <AssetCard key={asset.id} asset={asset} onDelete={handleDeleteAsset} onOpen={() => navigate(`/assets/${asset.id}`)} currency={user.currency} />
                      ))}
                    </Grid>
                  </TabPanel>
                ))}
              </SwipeableViews>
              <div>
                <Fab
                  color="primary"
                  aria-label={t('add_asset')}
                  onClick={handleOpenWizard}
                  sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    width: 64,
                    height: 64,
                    fontSize: 32,
                  }}
                >
                  <AddIcon />
                </Fab>
                {/* Asset Creation Wizard */}
                {isWizardOpen && <AssetCreationWizard
                  applyAsset={handleWizardSubmitAsset}
                  onCancel={handleCloseWizard}
                  t={t}
                  user={user}
                />}
                <ConfirmDialog
                  open={isDialogOpen}
                  onClose={() => setIsDialogOpen(false)}
                  onConfirm={handleConfirmDelete}
                  message={t('confirm_asset_deletion')}
                />
              </div>
            </>
          )}

      <LoadingModal open={isCreating} />
      <Modal
        open={isWizardOpen}
        onClose={handleCloseWizard}
        closeAfterTransition
        backdropprops={{ timeout: 1500 }}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={isWizardOpen}>
          <Box sx={{ background: 'white', boxShadow: 24, p: 4, borderRadius: 2, maxWidth: '500px', maxHeight: '90vh', overflow: 'auto' }}>
            <AssetCreationWizard applyAsset={handleWizardSubmitAsset} onCancel={handleCloseWizard} t={t} user={user} />
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default AssetsUser;
