import React, { useState, useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import {
  Container, Grid, Box, Tabs, Tab, Typography, Tooltip, Avatar, CardContent,
} from '@mui/material';
import MainCard from '../../utils/MainCard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { StyledCard, CardHeader, CardValue, IconWrapper } from './StyledCard';
import { formatChangeText } from '../../utils';
import UserCashflowChart from '../charts/UserCashflowChart';

const DashboardPro = () => {
  const { user } = useContext(UserContext);
  const { data } = useContext(DataContext); // Access preloaded data
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('tab1');
  const user_concierge = data.user_concierge || {};
  const concierge = data.concierge || {};
  const showCharts = user_concierge?.net_liq !== 0;
  const statsHistory = data.conciergeStatsHistory;

  const handleClick = (tooltip) => {
    setTooltipContent(tooltip);
    setTooltipOpen(true);
  };

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleTabChange = (value) => {
    if (value !== tabValue) setTabValue(value);
  };
  const cardsData = [
    {
      icon: <GroupIcon color="success" />,
      value: concierge.roi >= 0
        ? `+${(concierge.roi * 100).toFixed(2)}%`
        : `${(concierge.roi * 100).toFixed(2)}%`,
      label: t('clients_roi'),
      tooltip: t('tooltip_clients_roi'),
    },
    {
      icon: <StarIcon sx={{ color: '#FFC107' }} />,
      value: concierge.reviews_count
        ? (concierge.rating_count / concierge.reviews_count).toFixed(1).replace(/\.0$/, '')
        : 'NaN',
      label: t('reviews'),
      tooltip: t('tooltip_reviews'),
    },
    {
      icon: <HandshakeIcon color="primary" />,
      value: concierge.clients || 0, // Replace 0 with the actual data or fallback value
      label: t('clients'),
      tooltip: t('active_clients_tooltip'),
    },
    {
      icon: <BusinessCenterIcon color="primary" />,
      value: concierge.assets || 0, // Replace 0 with the actual data or fallback value
      label: t('assets'),
      tooltip: t('active_assets_tooltip'),
    },
  ];
  console.log('concierge', concierge);
  console.log('user_concierge', user_concierge);
  return (
    <Container mb={2}>
      {/* Main Overview */}
      <Box display="flex" justifyContent="center" my={2}>
        <MainCard
          title={t('revenue')}
          value={user_concierge.net_liq}
          m_change={statsHistory?.net_worth_mtd}
          y_change={statsHistory?.net_worth_ytd}
          currency={user_concierge.currency}
          tooltip={t('tooltip_revenue')}
          size="h4"
        />
      </Box>

     {/* Tabs Section */}
     {/* Tabs Section */}
{showCharts && (
  <>
    <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} centered>
      <Tab label={t('revenue')} value="tab1" />
      <Tab label={t('occupancy')} value="tab2" />
    </Tabs>

    {/* Tab Content */}
    <Box my={3}>
      {tabValue === 'tab1' && (
        <UserCashflowChart
          currency={user_concierge.currency}
          userCashflowChart={data.conciergeRevenueChart}
          strIncome={t('income')}
          strExpense={t('expense')}
        />
      )}
      {tabValue === 'tab2' && (
        <Typography variant="subtitle1">{t('chart_placeholder_occupancy')}</Typography>
      )}
    </Box>
  </>
)}

     {/* Cards Section */}
      <Grid container spacing={3} my={3}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Tooltip
              title={card.tooltip}
              open={tooltipOpen && tooltipContent === card.tooltip}
              onClose={handleClose}
              disableHoverListener
              disableFocusListener
              arrow
            >
              <StyledCard raised onClick={() => handleClick(card.tooltip)}>
                <CardContent>
                  <CardHeader>
                    <IconWrapper>
                      <Avatar sx={{ bgcolor: 'transparent' }}>
                        {card.icon}
                      </Avatar>
                    </IconWrapper>
                    <div>
                      <Typography variant="subtitle2" gutterBottom>
                        {card.label}
                      </Typography>
                      <CardValue sx={{ mb: 1 }}>
                        {card.value}
                      </CardValue>
                      {card.mtd ? formatChangeText(card.mtd, (card.mtd / Math.abs(card.value - card.mtd)) * 100, t('MTD'), user.currency) : null}
                    </div>
                  </CardHeader>
                </CardContent>
              </StyledCard>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DashboardPro;
